import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import { protectedResources } from './authConfig';
import { Provider} from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './slices/index.js'

import { loginRequest } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const req = {
    scopes: protectedResources.apiGetIssues.scopes
}

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  sessionStorage.setItem("account",msalInstance.getActiveAccount);
  localStorage.setItem("account",msalInstance.getActiveAccount);

}

let isRedirecting = false; // Flag to track redirection

msalInstance.addEventCallback((event) => {
  if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
  ){
        msalInstance.setActiveAccount(event.payload.account);
        msalInstance.acquireTokenSilent(req).then(async (response) => {   
            sessionStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
            localStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);          
        }).catch((error) => {console.log(error)});
    // }else if (!isRedirecting && !msalInstance.getActiveAccount()) {
    //     console.log('Redirecting page');
    //     isRedirecting = true;
    //     // Set a flag or token indicating the redirection
    //     sessionStorage.setItem('shouldRedirect', 'true');
    //      window.location.replace(
    //         'https://business.pdn.quotient.com/login?returnUrl=https://qcp.ms.helpshift.live/home'
    //      );
       }
});

// handle auth redired/do all initial setup for msal
msalInstance.handleRedirectPromise().then(authResult=>{
    // Check if user signed in 
    const account = msalInstance.getActiveAccount();

    if(!account){
        //window.sessionStorage.clear();
        //window.localStorage.clear();
      // redirect anonymous user to login page 
      //console.log("isRedirect ",isRedirecting);
      if(!isRedirecting){
        isRedirecting = true;
        localStorage.setItem('shouldRedirect', 'true');
        //window.location.replace("https://business.pdn.quotient.com/login?returnUrl=https://qcp.ms.helpshift.live/home");
      }else{
        msalInstance.loginRedirect(loginRequest);
      }      
    }
  }).catch(err=>{
    // TODO: Handle errors
    console.log(err);
    window.location.replace("/unauthorisedError");
});
//Dispatch the fetchPosts() before our root component renders

const store = configureStore({ reducer: rootReducer })

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
      
        <Provider store={ store }>
            <Router>
                <App instance={msalInstance} />
            </Router>
        </Provider>
    // </React.StrictMode>
);