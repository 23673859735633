import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
// import our issues selector
import { issuesSelector } from '../slices/issues'
import { Loader } from "../components/loader";
import 'moment-timezone';
import moment from 'moment-timezone';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
//import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
//import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';


const CustomIssueFields = () => {

    const { issues, loading, hasErrors } = useSelector(issuesSelector);
    const zone = moment.tz.guess();
    const [currentIssue, setCurrentIssue] = useState();
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    const shortcutFormatter = Intl.DateTimeFormat(moment.tz(zone).format("z"), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
    });


    useEffect(() => {
        if (issues[0]?.id === parseInt(id)) {
            setCurrentIssue(issues)
        }
    }, [issues, id])

    const renderCIFs = () => {
        if (loading) return <Loader />
        if (hasErrors) return <p> </p>

        return (
            <>

                {currentIssue?.[0] ?

                    <div className='outer-container-fixed-attributes'>
                        <div className='key-wrapper2' >
                            <div>
                                <EventAvailableIcon />
                            </div>
                            <div>
                                <div className="key">
                                    Created At
                                </div>
                                <div className="value">
                                    {shortcutFormatter.format(currentIssue[0].created_at ? currentIssue[0].created_at : "")}
                                </div>
                            </div>
                        </div>
                        {/* <div className='key-wrapper2'>
                            <div>
                                <EventRepeatIcon fontSize="small"/>
                            </div>
                            <div>
                                <div className="key">
                                    Updated At
                                </div>
                                <div className="value">
                                    {shortcutFormatter.format(currentIssue[0].updated_at ? currentIssue[0].updated_at : "" )}
                                </div>
                            </div>
                        </div> */}
                        <div className='key-wrapper2'>
                            <div>
                                <AccountBoxIcon />
                            </div>
                            <div>
                                <div className="key">
                                    Reported By
                                </div>
                                <div className="value">
                                    {currentIssue[0].author_name}
                                </div>
                            </div>
                        </div>
                        {/* <div className='key-wrapper2'>
                            <div>
                                <SupervisorAccountIcon/>
                            </div>
                            
                            <div>
                                <div className="key">
                                    Assigned To
                                </div>
                                <div className="value">
                                    {currentIssue[0].assignee_name ? currentIssue[0].assignee_name : "Unassigned"}
                                </div>
                            </div>
                        </div> */}
                    </div> : ""

                }
                <div style={{ border: '1px dotted black', marginBottom: '0.5em', height: "0.5em", backgroundColor: '#5c5b5b' }}></div>
                {currentIssue && currentIssue.map((item, index) => {
                    const entries = Object.entries(item.custom_fields)
                    return (
                        entries.map(([key, value]) => (
                            <div key={key + "1"}>
                                {key === 'organization' ? "" :
                                    <div className='outer-container' key={key}>
                                        <div className='key-wrapper'>
                                            <div className="circle" key={index}>
                                                {++index}
                                            </div>
                                            <div>
                                                <div className="key" key={index + 1}>
                                                    {key.split('_').join(' ')}
                                                </div>
                                                <div className="value" key={index + 2} style={{width:'11.8vw'}}>
                                                    {key === 'cc_optional' ? (
                                                        value['value'].split(';').map((email, emailIndex) => (
                                                            <div key={emailIndex}>{email}</div>
                                                        ))
                                                    ) : key === 'old_issue_link' || key === 'reopen_issue_link' ? (
                                                        <a
                                                            href={value['value']}
                                                            //target="_blank"
                                                            //rel="noopener noreferrer"
                                                            style={{ wordWrap: 'break-word'}}
                                                        >
                                                            {value['value']}
                                                        </a>
                                                    ) : (
                                                        value['value']
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }</div>
                        ))
                    );
                }
                )}
            </>
        );
    }

    return (
        <section>
            <div className='content'>
                {renderCIFs()}
            </div>
        </section>
    )
}

export default CustomIssueFields
