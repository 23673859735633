import {Button} from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import logopath from '../images/logo.svg';
import "../styles/App.css";
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useEffect,useState } from 'react';


export const NavigationBar = () => {
    const navigate = useNavigate();
    const { instance,accounts} = useMsal();
    const[org,setOrg] = useState("");
    const request = {
        ...loginRequest,
        account: accounts[0]
    }; 

    if (instance) {
        var activeAccount = instance.getActiveAccount();
    }
   
    useEffect(()=>{
         if(activeAccount && activeAccount.hasOwnProperty('idTokenClaims')){
            if(!activeAccount.idTokenClaims.hasOwnProperty('Organization')){
                console.log("Organization not found");
                 window.sessionStorage.clear();
                window.localStorage.clear();
                instance.loginRedirect(request);   
            }else{
                setOrg(activeAccount.idTokenClaims.Organization);
            }
        }
        // eslint-disable-next-line
    },[org,activeAccount,instance])
    
    // const deleteAllCookies = () => {
    //     const cookies = document.cookie.split(';');
      
    //     for (let i = 0; i < cookies.length; i++) {
    //       const cookie = cookies[i];
    //       const eqPos = cookie.indexOf('=');
    //       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //       document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    //     }
    //   };
      
    const handleLoginRedirect = () => {

        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        const cookieName = 'shouldRedirect';
        const cookieValue = "false";
        const expires = new Date();
        expires.setDate(expires.getDate() + 7); // Cookie expiration time (7 days in this example)
       
        // Set the cookie using document.cookie
        document.cookie = `${cookieName}=${cookieValue};expires=${expires.toUTCString()};path=/`;        
        instance.logoutRedirect();
        navigate("/");    
    };
    
    
    return (
        <>
        <AuthenticatedTemplate>
            <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center">
                <div className="container">
                    <a href="/" className="navbar-brand d-flex w-50 me-auto">
                    <img
                            alt=""
                            src={logopath}
                            width="40%"
                            height="40%"
                            className="logo"
                        />
                    </a>
                    
                    <div className="navbar-collapse collapse w-100" id="collapsingNavbar3">
                        <ul className="navbar-nav w-100 justify-content-center">
                            <li className="nav-item active">
                                <a className="nav-link" href="/home">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/createissue">Report Issue</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/helpcenter">FAQs</a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav ms-auto w-100 justify-content-end">
                            <li className='nav-itm'>
                                <Avatar sx={{ width: 30, height: 30 }}>{Array.from(activeAccount && activeAccount.name ? activeAccount.name : 'Unknown')[0]}</Avatar>
                            </li>       
                            <li className="nav-item">
                                
                                    <strong style={{paddingLeft:'0.5em',paddingRight:'0.5em'}}>
                                        {activeAccount && activeAccount.name ? activeAccount.name : 'Unknown'}
                                    </strong>
                                
                            </li>
                            <Button variant="secondary" onClick={handleLogoutRedirect} style={{marginRight:'5vw'}}>                              
                                Sign out
                            </Button>

                            <UnauthenticatedTemplate>
                                <Button variant="secondary" onClick={handleLoginRedirect}>
                                    Sign in
                                </Button>  
                            </UnauthenticatedTemplate>
                            
                        </ul>
                    </div>
                </div>
            </nav>

        </AuthenticatedTemplate>

        </>
    );
};