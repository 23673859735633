import React, { useState, useEffect } from 'react';

const CustomNoDataComponent = () => {
  const [displayText, setDisplayText] = useState(null);

  useEffect(() => {
    const delay = 2000; // 10 milliseconds
    const timeoutId = setTimeout(() => {
      setDisplayText("There are no issues to display");
    }, delay);

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts before the delay
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
    <div>
      {displayText !== null ? (
        <p>{displayText}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CustomNoDataComponent;
