

import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_signup_signin"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://helpshiftb2c.b2clogin.com/helpshiftb2c.onmicrosoft.com/B2C_1A_signup_signin"
        }
    },
    authorityDomain: "helpshiftb2c.b2clogin.com"
}

export const msalConfig = {
    auth: {
        clientId: "6c9ff445-974a-4c6e-9ccf-57b16d03547e", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        //redirectUri: "https://support.quotient.com/home", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        redirectUri: "/home",
        //redirectUrl:"https://qcp.ms.helpshift.live/home",
        postLogoutRedirectUri: "https://business.quotient.com/logout", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log("default")
                        return; 
                }
            }
        }
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiGetIssues: {
        endpoint: "https://coupons-customer-dashboard.azurewebsites.net/api/HttpTrigger1",
        scopes: ["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/Helpshift.getIssues"] // e.g. api://xxxxxx/access_as_user
    },
    apiGetIssueDetails: {
        endpoint: "https://coupons-customer-dashboard.azurewebsites.net/api/GetIssueDetails",
        scopes: ["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/GetIssueDetails"] // e.g. api://xxxxxx/access_as_user
    },
    apiCreateIssue: {
        endpoint: "https://coupons-customer-dashboard.azurewebsites.net/api/CreateIssue",
        scopes: ["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/CreateIssue"]
    },
    apiSendReply:{
        endpoint:"https://coupons-customer-dashboard.azurewebsites.net/api/SendUserReply",
        scopes:["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/SendUserReply"]
    },
    apiSendFeedback:{
        endpoint:"https://coupons-customer-dashboard.azurewebsites.net/api/AddCsatSurvey",
        scope:["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/AddCsatSurvey"]
    },
    apiEditIssue:{
        endpoint:"https://coupons-customer-dashboard.azurewebsites.net/api/EditIssue",
        scope:["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/EditIssue"]
    },
    apiReopenIssue:{
        endpoint:"https://coupons-customer-dashboard.azurewebsites.net/api/ReopenIssue",
        scope:["https://helpshiftb2c.onmicrosoft.com/f5341a54-b5dd-4140-ba20-4af5967b9a17/ReopenIssue"]
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
    scopes: [...protectedResources.apiGetIssues.scopes]
};
