import React from "react";
import { Table } from "../components/DataTable";
import "../styles/styles.css";
import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../authConfig-stag";
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { issueListSelector, SaveSelectedFilter } from '../slices/IssueList'
import SessionPoller from '../components/sessionPoller';

export const DisplayIssueTable = () => {
    const req = {
        scopes: protectedResources.apiGetIssues.scopes
    }
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const location = useLocation();
    const { filteredIssues, hasErrors } = useSelector(issueListSelector);
    const clickhandler = id => {
        console.log(id);
        navigate('/GetIssues?id=' + id);
        sessionStorage.setItem("renderCount", 1);
    }

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [filter, setFilter] = useState('myopen');

    useEffect(() => {
        // Remove the URL parameters from the visible URL without refreshing the page
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('execloginredirect');
        // eslint-disable-next-line
    }, [location.search, navigate]);

    useEffect(() => {

        const cookieName = 'shouldRedirect';
        const cookieValue = "false";
        const expires = new Date();
        expires.setDate(expires.getDate() + 7); // Cookie expiration time (7 days in this example)
        console.log("inside if");
        // Set the cookie using document.cookie
        document.cookie = `${cookieName}=${cookieValue};expires=${expires.toUTCString()};path=/`;
        setFilter("myopen");
        dispatch(SaveSelectedFilter("myopen"));
        // eslint-disable-next-line
    }, [account, accounts, instance]);

    const handleChange = (e) => {
        setFilter(e.target.value);
        dispatch(SaveSelectedFilter(e.target.value));
    };

    if (hasErrors) {
        instance.loginRedirect(req);
    }

    return (
        <>
            <SessionPoller />
            <AuthenticatedTemplate>
                <div style={{ paddingLeft: '2vw', paddingRight: '2vw' }}>
                    <select value={filter} onChange={handleChange} style={{ marginTop: '1vh', display: 'flex', position: 'absolute', zIndex: '9' }}>
                        <option value='All'>All Issues</option>
                        <option value='myopen'>My Open Issues</option>
                        <option value='myclosed'>My Resolved / Rejected Issues</option>
                    </select>
                    <Table data={filteredIssues} click={clickhandler} filter={filter} />
                </div>
            </AuthenticatedTemplate>
        </>
    );
}


