import { createSlice } from '@reduxjs/toolkit'
import { protectedResources } from "../authConfig";

export const initialState = {
  loading: true,
  hasErrors: false,
  issues: [],
  issueCreated: false,
  messageSent: false,
  isAuthorisedUser: false,
  createdIssueId: 0,
  timeInterval: 0,
  isRunning: true,
  apiCallCount: 0,
  apiResponseStatus: [],
  isFeedbackSubmitted: false,
  csatExpired: false,
  ccListUpdated: false,
  issueResolved: false,
  issueReopened: false,
  reopenedIssueId: 0,
  isIssueAlreadyOpened: false,
}

// A slice for issues with our 3 reducers
const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    getIssues: state => {
      state.loading = false //This is used in Create Issue with opposite state, show loader when loading is false
    },
    getIssuesSuccess: (state, { payload }) => {
      state.issues = payload
      state.loading = false
      state.hasErrors = false
      state.csatExpired = false
    },
    getMessagesSuccess: (state, { payload }) => {
      state.issues = payload
      state.loading = false
      state.hasErrors = false

    },
    getIssuesFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    getIssueCreated: (state, { payload }) => {
      state.loading = false
      state.hasErrors = false
      state.issueCreated = true
      state.createdIssueId = payload
      console.log("create issue id payload", payload)
    },
    getIssueCreationFailed: (state, { payload }) => {
      state.issueCreated = false
      state.hasErrors = true
      state.apiResponseStatus = payload
      console.log("status payload", payload);
    },
    messageSentSuccess: state => {
      state.messageSent = true
      state.hasErrors = false
      state.loading = false
    },
    setReplyMessageAuthentication: (state, { payload }) => {
      state.isAuthorisedUser = payload
      //console.log("isAuthorisedUser from payload",payload);
    },
    setIssueStatusResolved: (state, { payload }) => {
      state.issueResolved = payload
    },
    setIsRunning: (state, { payload }) => {
      state.isRunning = payload
      console.log("isRunning", payload)
    },
    setApiCallCount: (state, { payload }) => {
      state.apiCallCount = payload
    },
    setIssueCreated: state => {
      state.issueCreated = false
      console.log("set to false");
    },
    setIsFeedbackSubmitted: (state, { payload }) => {
      state.isFeedbackSubmitted = payload.status
      state.csatExpired = payload.expired
      // console.log("payload",payload)
      console.log("status", payload.status)
      // console.log("expired",payload.expired)
    },
    setCCListUpdatedStatusTrue: state => {
      state.ccListUpdated = true
      console.log("cc updated to ", state.ccListUpdated);
    },
    setCCListUpdatedStatusFalse: state => {
      state.ccListUpdated = false
      console.log("cc updated to ", state.ccListUpdated);
    },
    setIssueReopened: (state, { payload }) => {
      state.issueReopened = payload
    },
    setReopenedIssueId: (state, { payload }) => {
      state.reopenedIssueId = payload
    },
    setIsIssueAlreadyOpened: (state, { payload }) => {
      state.isIssueAlreadyOpened = payload
    }
  },
})


// Three actions generated from the slice
export const { setIsIssueAlreadyOpened, setReopenedIssueId, setIssueReopened, setIssueStatusResolved, setCCListUpdatedStatusTrue, setCCListUpdatedStatusFalse, setIsFeedbackSubmitted, getIssues, setIssueCreated, getIssuesSuccess, getIssuesFailure, setApiCallCount, setIsRunning, getMessagesSuccess, getIssueCreated, getIssueCreationFailed, messageSentSuccess, setReplyMessageAuthentication } = issuesSlice.actions
// A selector
export const issuesSelector = state => state.issues;

//export const issueSelector = state => state.messages;
// The reducer
export default issuesSlice.reducer

// Asynchronous thunk action


export function issueCreatedStatus() {
  return async dispatch => {
    dispatch(setIssueCreated());
    console.log("executed redux")
  }
}

export function StoreIsRunning(status) {
  return async dispatch => {
    dispatch(setIsRunning(status));
  }
}

export function StoreApiCallCount(count) {
  return async dispatch => {
    dispatch(setApiCallCount(count));
  }
}

export function CreateIssue(formData, file) {
  return async dispatch => {
    console.log("formdata ", formData);
    //const ccField = process.env.REACT_APP_CIF_CC;
    //console.log(file);

    const token = localStorage.getItem("SavedToken");
    const headers = new Headers();
    const params = {
      issue_title: formData.issue_title,
      issue_description: formData.issue_description,
      request_type: formData.request_type,
      service_request: formData?.service_request_type,
      incident_type: formData?.incident_type,
      priority: formData.priority,
      product: formData.product,
    };

    if (formData.cc) {
      console.log("inside if ", formData.cc);
      params.cc_optional = formData.cc;
    }


    console.log("cc_optional", params);

    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    var formdata = new FormData();
    formdata.append("file", file);

    headers.append("Authorization", token);

    const options_with_attachment = {
      method: "POST",
      headers: headers,
      body: formdata
    };

    const options_without_attachment = {
      method: "POST",
      headers: headers
    };

    try {
      file ? console.log("with attachment") : console.log("without attachment")
      const response = await fetch(protectedResources.apiCreateIssue.endpoint + '?' + query, file ? options_with_attachment : options_without_attachment)
      const data = await response?.json()
      data.issue_created ? dispatch(getIssueCreated(data.new_issue_json.id)) : dispatch(getIssueCreationFailed(data.status_text))
    } catch (error) {
      console.log(error);
    }
  }
}

export function fetchIssueDetails(id, userEmail) {

  return async dispatch => {
    const email = userEmail
    const token = localStorage.getItem("SavedToken");
    const headers = new Headers();
    const params = {
      issue_id: id
    };

    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    };

    headers.append("Authorization", token);

    try {
      const response = await fetch(protectedResources.apiGetIssueDetails.endpoint + '?' + query, options)
      const data = await response.json()
      const conv = data['issue_details'];
      //console.log(conv[0]);
      localStorage.setItem("Status", data.status_text);
      //console.log(conv[0].state_data.state);
      if (conv[0].state_data.state === 'resolved' || conv[0].state_data.state === 'rejected') {
        dispatch(setReplyMessageAuthentication(false));
        dispatch(setIssueStatusResolved(true));

         // Check if custom_fields and old_issue_link exist
         if (conv[0]?.custom_fields && conv[0].custom_fields.reopen_issue_link) {
          // Check if the value of old_issue_link is not empty or only whitespace
          const reopenIssueLinkValue = conv[0].custom_fields.reopen_issue_link.value;
          if (reopenIssueLinkValue && reopenIssueLinkValue.trim() !== "") {
            // The old_issue_link value is not empty
            console.log("reopen_issue_link value is not empty:", reopenIssueLinkValue);
            dispatch(setIsIssueAlreadyOpened(true));
          } else {
            // The old_issue_link value is empty or only whitespace
            console.log("reopen_issue_link value is empty or whitespace");
            dispatch(setIsIssueAlreadyOpened(false));
          }
        } else {
          console.log("reopen_issue_link value is empty or whitespace");
          dispatch(setIsIssueAlreadyOpened(false));
        }

      } else {
        dispatch(setIssueStatusResolved(false));
        if (conv[0]?.custom_fields.hasOwnProperty('cc_optional')) {
          var ccEmails = conv[0]?.custom_fields?.cc_optional.value.split(';');
          //console.log("from redux ",  ccEmails)
        } else {
          ccEmails = conv[0].author_email
        }

        var isUserAuthorised = false;
        //console.log("inside if",ccEmails);
        for (let i = 0; i < ccEmails.length; i++) {
          if (email.toUpperCase() === conv[0].author_email.toUpperCase() || email.toUpperCase() === ccEmails[i].toUpperCase()) {
            dispatch(setReplyMessageAuthentication(true));
            isUserAuthorised = true;
          }
        }
        if (!isUserAuthorised) {
          dispatch(setReplyMessageAuthentication(false));
        }

      }
      dispatch(getMessagesSuccess(conv))
    } catch (error) {
      dispatch(getIssuesFailure())
    }
  }
}

export function SendReply(formData, file) {
  return async dispatch => {

    console.log(file);
    const token = localStorage.getItem("SavedToken");
    var msgType = file ? "Attachment" : formData.message_type
    const headers = new Headers();
    const params = {
      issue_id: formData.issue_id,
      user_reply: formData.user_reply,
      message_type: msgType
    };

    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    var formdata = new FormData();
    formdata.append("file", file);

    const options_with_attachment = {
      method: "POST",
      headers: headers,
      body: formdata
    };

    const options_without_attachment = {
      method: "POST",
      headers: headers
    };

    headers.append("Authorization", token);
    try {
      const response = await fetch(protectedResources.apiSendReply.endpoint + '?' + query, file ? options_with_attachment : options_without_attachment)
      const data = await response.json()
      console.log(data['status_text']);
      await dispatch(fetchIssueDetails(formData.issue_id, formData.user_email))
    } catch (error) {
      dispatch(getIssuesFailure())
    }
  }
}

export function updateCC(emails, id) {
  return async dispatch => {

    console.log("data JSON object ", emails);
    const token = localStorage.getItem("SavedToken");
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const params = {
      issue_id: id
    };

    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    headers.append("Authorization", token);

    const options_with_attachment = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(emails)
    };

    //console.log("emails ", emails);
    try {
      const response = await fetch(protectedResources.apiEditIssue.endpoint + '?' + query, options_with_attachment);
      const data = await response.json()
      //console.log(data['issue_updated']);
      await dispatch(setCCListUpdatedStatusTrue(data['issue_updated']));
    } catch (error) {
      dispatch(getIssuesFailure())
      await dispatch(setCCListUpdatedStatusFalse(false));
    }
  }
}

export function SendFeedback(formData) {
  return async dispatch => {
    var res = {
      status: false,
      expired: false
    }
    dispatch(setIsFeedbackSubmitted(res));
    const params = {
      issue_id: formData.issue_id.toString(),
      feedback_rating: formData.feedback_rating.toString(),
      feedback_comment: formData.feedback_comment.toString()
    };

    const token = localStorage.getItem("SavedToken");
    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    };

    try {
      const response = await fetch(protectedResources.apiSendFeedback.endpoint, options)
      const data = await response.json()
      if (response.status === 410) {
        console.log("inside 410")
        res = {
          status: true,
          expired: true
        }
        dispatch(setIsFeedbackSubmitted(res));
      }
      console.log(data['status_text']);

      // await dispatch(fetchIssueDetails(formData.issue_id,formData.user_email));

    } catch (error) {
      console.log("inside catch");
      res = {
        status: false,
        expired: false
      }
      dispatch(setIsFeedbackSubmitted(res));
    }

  }
}

export function ReopenIssue(issue_id, reopen_reason) {
  return async (dispatch) => {
    // Convert issue_id to a number if needed
    const parsedIssueId = parseInt(issue_id, 10); // Assuming it's an integer
    if (isNaN(parsedIssueId)) {
      // Handle invalid issue_id here
      console.log("invalid issue_id");
      return;
    }

    const Body = {
      event_type: "portal_reopen_issue",
      data: {
        id: parsedIssueId,
        custom_issue_fields: {
          reason_for_reopening: {
            type: "multiline",
            val: reopen_reason+" ",
          },
          reopen_issue: {
            type: "checkbox",
            val: true,
          },
        },
      },
    };
   // console.log("reopen issue body ", Body);
    const token = localStorage.getItem("SavedToken");
    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(Body),
    };

    try {
      const response = await fetch(
        protectedResources.apiReopenIssue.endpoint,
        options
      );

      if (response.ok) {
        // Request was successful
        const data = await response.json();
        const urlParts = data.reopen_issue_link.split('=');
        const issueId = urlParts[urlParts.length - 1];
        dispatch(setIssueReopened(true));
        dispatch(setReopenedIssueId(issueId));
      } else {
        const errorData = await response.json();
        console.error("API error:", errorData);
        dispatch(setIssueReopened(false));
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
      dispatch(setIssueReopened(false));
    }
  };
}
