import React, { useMemo, useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
//import { Loader } from "./loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import { fetchAllIssues, issueListSelector } from '../slices/IssueList'
import { useDispatch, useSelector } from 'react-redux'
import CustomNoDataComponent from "./CustomNoDataComponent";

export const Table = props => {
  const { selectedFilter } = useSelector(issueListSelector)

  const [pending, setPending] = useState(true);
  const zone = moment.tz.guess();

  const [filterValue, setFilterValue] = useState("myopen");

  const dispatch = useDispatch()

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [issueData, setIssueData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);


  const request = {
    ...loginRequest,
    account: accounts[0]
  };

  let author = "";
  let activeAccount = instance.getActiveAccount();

  if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
    if (activeAccount.idTokenClaims.hasOwnProperty('email')) {
      author = activeAccount.idTokenClaims.email.toLowerCase();
    }
  }

  useEffect(() => {
    console.log("props ", props);
    if (account) {
      instance.acquireTokenSilent(request).then(async (response) => {
        if (!response.idTokenClaims.hasOwnProperty('Organization')) {
          instance.loginRedirect(request);
        }
        sessionStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
        localStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);

      }).catch((error) => {
        console.log(error);
      });
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    console.log("test");
    console.log("selectedFilterValue ", selectedFilter);
    setFilterValue(filterValue);
    fetchIssues(1, perPage, selectedFilter);
    // eslint-disable-next-line
  }, [selectedFilter])

  const fetchIssues = async (page, pageSize, filter) => {
    setLoading(true);

    const headers = new Headers();
    const options = {
      method: "GET",
      headers: headers
    };

    let queryParams = ""
    let queryString = "";
    console.log("filter received ",filter);
    var ch = 'myopen';    
    if(filter){
      ch = filter;
    }

    switch (ch) {
      case 'myopen':
        queryParams = `&state=new,agent-replied,waiting-for-agent,new-for-agent&author_emails=${author}`;
        break;
      case 'myclosed':
        queryParams = `&state=resolved,rejected&author_emails=${author}`;
        break;
      case 'All':
        queryString = `?page=${page}&page-size=${pageSize}`;
        break;
      default:
        queryParams = "";
        break;
    }

    queryString = `?page=${page}&page-size=${pageSize}${queryParams}`;

    const accessToken = localStorage.getItem("SavedToken");
    headers.append("Authorization", accessToken);
    headers.append("Access-Control-Allow-Origin", '*');
    await fetch(protectedResources.apiGetIssues.endpoint + queryString, options)
      .then(response => response.json())
      .then(response => {
        console.log("fetch user api response ", response);
        setIssueData(response['issues']);
        setTotalRows(response['total-hits']);
        dispatch(fetchAllIssues(response['issues'], author.toLowerCase()))
        console.log("issues data ", response['issues']);
        //filterData(filterValue,response['issues']);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };


  const handlePageChange = page => {
    fetchIssues(page, perPage, selectedFilter);
  };

  const handlePerRowsChange = async (pageSize, page) => {
    console.log("handlePerRowsChange ", pageSize);
    fetchIssues(page, pageSize, selectedFilter);
    setPerPage(pageSize);
  };


  const shortcutFormatter = Intl.DateTimeFormat(moment.tz(zone).format("z"), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  const columns = [
    {
      name: "Title",
      selector: row => `${row.issue_title}`,
      sortable: true,
      grow: 2
    },
    {
      name: "Issue Id",
      selector: row => `${row.issue_id}`,
      sortable: true,
      hide: "sm"
    },
    {
      name: "Status",
      selector: row => `${row.issue_status}`,
      sortable: true,
      cell: row =>
      (
        <>{(row.issue_status) === 'new' || (row.issue_status) === 'new-for-agent' || (row.issue_status) === 'waiting-for-agent' ? <div className="issue-status-new">{row.issue_status === "new" ? "new" : "Open"/*+row.assignee_name.split(" ")[0]*/}</div> :
          (row.issue_status) === 'agent-replied' ? <div className="issue-status-new">{/*row.assignee_name.split(" ")[0]+*/"Open"}</div> :
            <div className="issue-status-rejected">{row.issue_status}</div>}</>

      )
    },
    {
      name: "Created At (" + moment.tz(zone).format("z") + ")",
      selector: row => `${row.created_at}`,
      sortable: true,
      hide: "md",
      cell: row =>
      (

        <>{shortcutFormatter.format(row.created_at)}</>

      )
    },
    {
      name: "Last Updated (" + moment.tz(zone).format("z") + ")",
      selector: row => `${row.updated_at}`,
      sortable: true,
      hide: "md",
      cell: row =>
      (

        <>{shortcutFormatter.format(row.updated_at)}</>

      )
    },
    {
      name: "",
      button: true,
      cell: row =>
      (
        <>
          <button
            onClick={() => props.click(row.issue_id)}
            style={{ marginRight: "5px", padding: '0.9em' }}
          >
            View Issue
          </button>
        </>
      )
    }
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = issueData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const navigate = useNavigate();

  const handleRowClicked = row => {
    console.log(row.issue_id);
    navigate('/GetIssues?id=' + row.issue_id);
    sessionStorage.setItem("renderCount", 1);

  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [pending]);

  return (
     <DataTable
        title=""
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        progressComponent={<div>Loading...</div>}
        defaultSortField="name"
        paginationPerPage={10}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponent}
        noDataComponent={<CustomNoDataComponent />}
        onRowClicked={handleRowClicked}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
  );
};

//export default Table;
