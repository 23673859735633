import { useEffect, useState } from "react";
import { useSelector } from 'react-redux' 
// import our issues selector
import { issuesSelector } from '../slices/issues'	
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const IssueTitle = () => {
    

    // use the hook and selector
    const { issues, loading, hasErrors } = useSelector(issuesSelector)
    const [currentIssue,setCurrentIssue] = useState();
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    

    useEffect(()=>{
        if(issues[0]?.id === parseInt(id)){
        setCurrentIssue(issues)}
         // eslint-disable-next-line
    },[issues])

    const renderTitle = () => {
        if (loading) return <p>...</p>
        if (hasErrors) return <p> </p>
       
        return (       
            <>   
                {  currentIssue && currentIssue.map((item,index ) => {
                    return (
                        
                <div className="tagarea" key={index} >
                    <Row>
                    <Col style={{marginRight:'1em',fontSize:'medium',textAlign:'left'}} xs={10}>                       
                         {item.title}
                    </Col>     
                     <Col style={{fontWeight:'bolder'}}xs={1}>
                        <div className="issueid">#{item.id}</div>                            
                     </Col>
                     </Row>                        
                </div>
                      
                    )             
                })}                  
            </>             
         );        
    }

    return (
        <section>
           <div className='content'>
            {renderTitle()}
          </div>
        </section>
      )
}    
    
export default IssueTitle