import { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from "../authConfig";
import { Loader } from "./loader";
import { protectedResources } from "../authConfig";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

function ProtectedRoute({ children }) {
    const [authorised, setAuthorised] = useState(false);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    let activeAccount = instance.getActiveAccount();
    const req = {
        scopes: protectedResources.apiGetIssues.scopes
    }


    useEffect(() => {
        let isRedirecting = "false"; // Flag to track redirection
        //console.log("instance", instance);
        //console.log("isAuthenticated", isAuthenticated);
        if (isAuthenticated) {
            let activeAccount = instance.getActiveAccount();
            if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
                if (!activeAccount.idTokenClaims.hasOwnProperty('Organization')) {
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
                }
            }
        }

        if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
            instance.acquireTokenSilent(req).then(async (response) => {
                setAuthorised(true);
                sessionStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
                localStorage.setItem("SavedToken", 'Bearer ' + response.accessToken);
            }).catch((error) => {
                console.log(error)
                setAuthorised(false);
                window.sessionStorage.clear();
                window.localStorage.clear();
                instance.loginRedirect(loginRequest).catch((error) => console.log(error));
            });
        } else {
            setAuthorised(false);
            window.sessionStorage.clear();
            window.localStorage.clear();
            //instance.loginRedirect(loginRequest).catch((error) => console.log(error));
            //window.location.replace("https://business.pdn.quotient.com/signin");

            console.log("isRedirect ", isRedirecting);
            // Read cookie value using document.cookie
            const cookies = document.cookie.split(';');
            const cookie = cookies.find((c) => c.trim().startsWith('shouldRedirect='));
            if (cookie) {
                const [, value] = cookie.split('=');
                var storedRedirectingValue = value;
                console.log("stored isRedirect ", value);
            }else{
                storedRedirectingValue = "false";
            }

            console.log("storedRedirectingValue ",storedRedirectingValue);
            console.log("if condition ", (storedRedirectingValue === "false"));
            const query = new URLSearchParams(window.location.search);
            const execloginredirect = query.get('execloginredirect')
            
            if (storedRedirectingValue === "false" || !execloginredirect || execloginredirect === null) {
                const cookieName = 'shouldRedirect';
                const cookieValue = "notfalse";
                const expires = new Date();
                expires.setTime(expires.getTime() + (1 * 60 * 1000)); // Set expiration to 7 minutes from now
                console.log("inside if");
                // Set the cookie using document.cookie
               
                setTimeout(() => {
                    document.cookie = `${cookieName}=${cookieValue};expires=${expires.toUTCString()};path=/`;
                    window.location.replace("https://business.quotient.com/login?returnUrl="+ encodeURIComponent("https://support.quotient.com/home?execloginredirect=true"));
                  }, 4000);
                  console.log("redirect missed");
            } else {
                console.log("inside else normal redirec");
                //document.cookie = `${'shouldRedirect'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                instance.loginRedirect(loginRequest);
            }
        }
        // eslint-disable-next-line
    }, [activeAccount, instance, isAuthenticated])
    if (!authorised) {
        return <Loader />
    }
    return (
        authorised ? children : instance.loginRedirect(loginRequest).catch((error) => console.log(error))
    );
}

export default ProtectedRoute;
