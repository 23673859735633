
import ReactStars from "react-rating-stars-component";
import { issuesSelector} from '../slices/issues';
import { useEffect,useState } from "react";
import { useSelector} from "react-redux";



export const FeedbackComment = () => {
    
    const [showRating,setShowRating] = useState(false);
    const [rating,setRating] = useState(0);
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    
    const { issues } = useSelector(issuesSelector);
    
    useEffect(()=>{
            if(issues[0]?.hasOwnProperty('feedback_rating')){
                if(issues[0]?.id === parseInt(id)){
                setRating(issues[0].feedback_rating);
            }else{
                setShowRating(false);            
            }
        }    
         // eslint-disable-next-line
    },[issues])
    
    const feedback_rating = {
        size: 16,
        value: rating,
        edit: false
    };    
  return (
        <>
        {
            showRating ?
            <div className="feedback-msg-outer-container"> 
                <div className="feedback-msg-inner">
                    <p style={{color:'orange'}}>Customer Satisfaction Survey Rating </p>
                    <div className="grid-container">
                        <div style={{paddingTop:'1em',color:'gray'}}>
                            {issues[0]?.messages[issues[0]?.messages.length-1]?.body}
                        </div>
                        <div>
                            <ReactStars {...feedback_rating}/>
                        </div>
                    </div>
                </div>
            </div>           
            :""}
        </>
        );
};


