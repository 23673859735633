import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { SendReply, issuesSelector, updateCC } from '../slices/issues'
import { Loader } from "../components/loader";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
// eslint-disable-next-line
import { StoreApiCallCount, StoreIsRunning, setCCListUpdatedStatusFalse, ReopenIssue } from '../slices/issues';
import { fetchIssueListUsingToken } from '../slices/IssueList';
import { alert } from "react-alert-confirm";
import { Tooltip, IconButton, Typography, Alert } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import '../styles/loader.css';
//import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export const ReplyMessageForm = () => {
       // const navigate = useNavigate();
        const { issues } = useSelector(issuesSelector);
        const dispatch = useDispatch()
        const { instance } = useMsal();
        const { loading, isAuthorisedUser, issueResolved, ccListUpdated, issueReopened, reopenedIssueId, isIssueAlreadyOpened } = useSelector(issuesSelector)
        const query = new URLSearchParams(useLocation().search);
        const id = query.get('id');
        const messageType = "Text";
        const RefIssueDescription = useRef(null);
        const [fileError, setFileError] = useState(false);
        const [files, setFiles] = useState();
        const [fileErrorMsg, setFileErrorMsg] = useState();
        const [isAttachment, setIsAttachment] = useState(false);
        const RefFileInput = useRef(null);
        const [count, setCount] = useState(0);
        const RefCcInput = useRef(null);
        const RefCcLoader = useRef(null);
        const [emails, setEmails] = useState(issues[0]?.custom_fields?.cc_optional?.value || ' ');
        const [isEmailValid, setIsEmailValid] = useState(true);
        const [ccVisibility, setCCVisibility] = useState(false);
        const [open, setOpen] = useState(false);
        const [vertical] = useState('bottom');
        const [horizontal] = useState('center');
        const prevCcListUpdated = useRef(ccListUpdated);
        const [showLoader, setShowLoader] = useState('none');
        const [dialogOpen, setDialogOpen] = useState(false);
        const [reopenReason, setReopenReason] = useState();
        const [openBackdrop, setOpenBackdrop] = useState(false);

        let userEmail;
        if (instance) {
                userEmail = instance.getActiveAccount().idTokenClaims.email
        }

        const editCCList = (e) => {
                setShowLoader('flex');
                setTimeout(() => {
                        RefCcLoader.current.scrollIntoView({ behavior: 'smooth' });
                }, 500); // Adjust the delay as needed

                dispatch(setCCListUpdatedStatusFalse(false))
                setCCVisibility(true);
                setEmails(issues[0]?.custom_fields?.cc_optional?.value)
                if (!isAuthorisedUser && !issueResolved) {
                        const currentEmails = issues[0]?.custom_fields?.cc_optional?.value || "" // Ensure it's a string even if it's initially null or undefined
                        let updatedEmails = "";
                        if (currentEmails.length > 0) {
                                updatedEmails = currentEmails + ";" + userEmail;
                        } else {
                                updatedEmails = userEmail;
                        }
                        setEmails(updatedEmails);
                        saveCC(updatedEmails);
                }
        }

        const saveCC = (updatedEmails) => {
                let emailsToSave = "";
                if (typeof updatedEmails === 'string' && updatedEmails.includes(userEmail)) {
                        emailsToSave = updatedEmails;
                } else {
                        emailsToSave = emails;
                }
                
                const data = {
                        custom_fields: {
                                cc_optional: {
                                        type: 'singleline',
                                        value: emailsToSave
                                },
                        }
                }
            
                dispatch(updateCC(data, id));
                setCCVisibility(false);
        }

        const onInputChange = (e) => {
                if (e.target.value === "") {
                        setEmails(" ");
                } else {
                        setEmails(e.target.value);
                }
                setIsEmailValid(validateEmails(e.target.value));
        }

        const validateEmails = (input) => {
                if (input === '') {
                        return true;
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const emailsArray = input.split(';');
                for (let i = 0; i < emailsArray.length; i++) {
                        const trimmedEmail = emailsArray[i].trim();
                        if (!emailRegex.test(trimmedEmail)) {
                                return false;
                        }
                        if (trimmedEmail !== emailsArray[i].trim()) {
                                // The email address has extra whitespace characters
                                return false;
                        }
                }
                return true;
        };

        const StyledTooltip = styled(({ className, ...props }) => (
                <Tooltip {...props} classes={{ tooltip: className }} />
        ))(({ theme }) => ({
                backgroundColor: 'white',
                color: 'gray',
                fontSize: 'normal',
                padding: '8px',
                borderRadius: '5px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
        }));

        const tooltipTextStyle = {
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: 'white',
                color: 'gray'
        };

        function InfoIcon({ tooltipText }) {
                return (
                        <StyledTooltip title={tooltipText} arrow>
                                <IconButton>
                                        <HelpOutlineIcon />
                                </IconButton>
                        </StyledTooltip>
                );
        }

        const openConfirm = async () => {
                await alert(fileErrorMsg);
                setFileError(true);
        };

        const submitForm = (e) => {

                dispatch(SendReply(formData, files));
                dispatch(StoreApiCallCount(1));
                dispatch(StoreIsRunning(true));
                RefIssueDescription.current.value = "";
                RefFileInput.current.value = "";
                setIsAttachment(false);
                setFiles(null);
                e.preventDefault();

        }

        const [formData, setFormData] = useState({
                issue_id: id,
                user_reply: "",
                message_type: messageType,
                user_email: ""
                // cc_optional: emails
        });

        const updateFormData = e => {
                setFormData({ ...formData, [e.target.name]: e.target.value, issue_id: id, user_email: userEmail });
        }

        const fileupload = (e) => {
                setCount(prevCount => prevCount + 1);
                if (e.target.files.length > 0) {

                        if (e.target.files[0].name.length > 70 || e.target.files[0].size > 5242880) {
                                e.target.files[0].name.length > 70 ? setFileErrorMsg("File name is too long, file name should have less than 70 characters") : setFileErrorMsg("File Size is too large, File size should be less than 5 MB")
                                e.target.value = ''
                                setFileError(true);

                        }
                        else {

                                setFiles(e.target.files[0]);
                                setFileError(false);
                                setIsAttachment(true);
                        }
                }
        }

        function removeAttachment() {
                RefFileInput.current.value = "";
                setFiles(null);
                setIsAttachment(false);
        }

        const sleep = ms =>
                new Promise(resolve => setTimeout(resolve, ms));

        useEffect(() => {
                setCCVisibility(false);
                if (fileError) { openConfirm() };
                // eslint-disable-next-line
        }, [count, id])

        useEffect(() => {
                RefCcInput.current?.scrollIntoView({ behavior: 'smooth' });
                if (ccListUpdated !== prevCcListUpdated.current) {
                        setOpen(ccListUpdated);
                }
                setShowLoader('none');
                prevCcListUpdated.current = ccListUpdated;
                // eslint-disable-next-line
        }, [ccVisibility, ccListUpdated])

        useEffect(() => {
                if (issueReopened) {
                        sleep(2000).then(() => {
                                setOpenBackdrop(false);
                                dispatch(fetchIssueListUsingToken());
                                if (reopenedIssueId !== 0) {
                                       window.location.replace(`/Getissues?id=${reopenedIssueId}`, { replace: true })
                                }
                        });
                }
        // eslint-disable-next-line
        }, [issueReopened])

        const handleClose = () => {
                setOpen(false);
        }

        const handleCloseDialog = () => {
                setDialogOpen(false);
        }

        const openDialog = () => {
                setDialogOpen(true);
        }

        const reopenIssue = () => {
                if(reopenReason==="" || reopenReason === undefined){
                        setReopenReason(" ");
                }
                setDialogOpen(false);
                setOpenBackdrop(true);
                dispatch(ReopenIssue(id, reopenReason));
        }

        const handleTextFieldChange = (event) => {
                // Update the state variable with the current text value
                setReopenReason(event.target.value);
        };

        // const handleBackdropClick = () => {
        //         setOpenBackdrop(false);
        // }

        return (
                <form onSubmit={submitForm}>
                        {loading ? <Loader /> : ""}
                        <textarea className="textareasendreply"
                                ref={RefIssueDescription}
                                rows="4"
                                cols="0"
                                onChange={e => updateFormData(e)}
                                type="text"
                                name="user_reply"
                                required
                                disabled={!isAuthorisedUser}
                                placeholder={!isAuthorisedUser ? "You can not reply to this Issue" : ""}
                        />
                        <div className="ccloader" style={{ display: showLoader }} ref={RefCcLoader}>
                                <span className="spinner1"></span>
                                <span className="spinner2"></span>
                                <span className="spinner3"></span>
                        </div>
                        <div style={{ display: "flex" }}>
                                <div style={{ display: 'flex', width: '65%' }}>
                                        <input type="file" onChange={fileupload} style={{ padding: '0.5em', width: 'fit-content', border: 'none' }} ref={RefFileInput} disabled={!isAuthorisedUser} />
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} className="close" onClick={removeAttachment} type='button' >
                                                <svg height="18px" id="Layer_1" style={{ enableBackground: 'new 0 0 512 512', visibility: isAttachment ? 'visible' : 'hidden' }} version="1.1" viewBox="0 0 512 512" width="18px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#69727D" d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
                                        </button>
                                </div>
                                <button className={!isAuthorisedUser ? "afterResolvedButtonStyle" : "sendreplybutton"} type="submit" disabled={!isAuthorisedUser} >Send Reply</button>
                                <button className={/*issueResolved*/!isAuthorisedUser ? "afterResolvedButtonStyle" : "SendReplyFormCCButton"} type="button" onClick={editCCList} disabled={!isAuthorisedUser}> {/*issueResolved || isAuthorisedUser ? "CC" : "Join Thread"*/}CC </button>
                                <button className={isAuthorisedUser ? "afterResolvedButtonStyleReopen" : "SendReplyFormReopenButton"} type="button" onClick={openDialog} disabled={isAuthorisedUser || isIssueAlreadyOpened }> {isAuthorisedUser || isIssueAlreadyOpened ? "Already Reopened":"Reopen"} </button>
                        </div>

                        {
                                ccVisibility &&

                                <div style={{ display: 'flex', marginTop: '0.5em' }}>
                                        <div style={{ flexBasis: '15%' }}>
                                                <p><strong>CC:</strong><InfoIcon tooltipText={<Typography style={tooltipTextStyle} > "Enter semicolon separated Emails ex. mymail@org.com;email2@org.com (No spaces after semicolon)"</Typography>} /></p>
                                        </div>
                                        <div style={{ flexGrow: 1 }}>
                                                {isEmailValid ? null : <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'small', textAlign: 'center' }}>Please enter valid email addresses separated by semicolon (No Spaces allowed after semicolon)</p>}
                                                <input name='cc' ref={RefCcInput} className='ccListInput' type="text" defaultValue={emails} onChange={onInputChange} disabled={!isAuthorisedUser || ccListUpdated} />
                                        </div>
                                        <div style={{ flexBasis: '5%' }}>
                                                <button className={!isAuthorisedUser ? "afterResolvedButtonStyle" : "saveCCButton"} type="button" onClick={saveCC} disabled={!isAuthorisedUser || !isEmailValid} >Save</button>
                                        </div>

                                </div>
                        }


                        <Snackbar
                                anchorOrigin={{ vertical, horizontal }}
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                        CC Field Updated Successfully!
                                </Alert>
                        </Snackbar>

                        <Dialog open={dialogOpen} onClose={handleClose}>
                                <DialogTitle>Reopen Issue</DialogTitle>
                                <DialogContent>
                                        <DialogContentText>
                                                Please provide a reason for reopening the issue.
                                        </DialogContentText>
                                        <TextField
                                                autoFocus
                                                multiline
                                                margin="dense"
                                                id="name"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                value={reopenReason} // Bind the value to the state variable
                                                onChange={handleTextFieldChange} // Handle text field changes
                                        />
                                </DialogContent>
                                <DialogActions>
                                        <Button
                                                onClick={handleCloseDialog}
                                                sx={{
                                                        bgcolor: '#0cb492',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        ':hover': {
                                                                bgcolor: '#099075', // theme.palette.primary.main
                                                                color: 'white',
                                                        },
                                                }}
                                        >Cancel</Button>
                                        <Button
                                                onClick={reopenIssue}
                                                sx={{
                                                        bgcolor: '#0cb492',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        ':hover': {
                                                                bgcolor: '#099075', // theme.palette.primary.main
                                                                color: 'white',
                                                        },
                                                }}
                                        >Reopen</Button>
                                </DialogActions>
                        </Dialog>

                        <div>
                                <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={openBackdrop}
                                // onClick={handleBackdropClick}                                
                                >
                                        <CircularProgress color="inherit" />
                                </Backdrop>
                        </div>
                </form>
        );
};


