import React, { useState,useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Typography from '@mui/material/Typography';
import StarsRating from "react-star-rate";
import { SendFeedback,issuesSelector } from "../slices/issues";
import { useDispatch,useSelector } from "react-redux";


export const FeedbackForm = () => { 
        
        const dispatch = useDispatch();
        const { instance} = useMsal();
        const { isFeedbackSubmitted} = useSelector(issuesSelector)     
        const query = new URLSearchParams(useLocation().search);
        const id = query.get('id');
        const RefRating = useRef(null);
        const RefComment = useRef(null);
        const [rating, setRating] = useState(0);
        const RefSubmit = useRef(null);
        const [comment,setComment] = useState("");
        const [isDisable,setIsDisable] = useState(false);

        let userEmail;
        if (instance) {
            userEmail = instance.getActiveAccount().idTokenClaims.email
        }

        const submitForm=(e)=>{
            let { name, value } = e.target;
            setFormData({ 
                ...formData, [name]:[value]
            });
            dispatch(SendFeedback(formData));
            setRating(0);
            setIsDisable(true);
            RefRating.current.value="";
            RefComment.current.value="";
            e.preventDefault();
            console.log(formData);
        }

        const [formData, setFormData] = useState({
            issue_id: id,
            feedback_rating: rating,
            user_email: userEmail,
            feedback_comment: comment,
        });
        
        const onChange=(e)=>{
            setComment(e.target.value);
            setFormData({
                issue_id: id,
                feedback_rating: rating,
                user_email: userEmail,
                feedback_comment:e.target.value,

            });
        }
        useEffect(()=>{
            setIsDisable(false);
            if(isFeedbackSubmitted){
                console.log("isfeedbacksubmitted",isFeedbackSubmitted)
                setIsDisable(false);
            }
        },[isFeedbackSubmitted,id])
        
        
  return (
          <form onSubmit={submitForm} className="feedbackForm">
                    <Typography style={{color:'orange'}} component="legend" className="typography">Customer Satisfaction Survey Rating </Typography>
                    <StarsRating
                        name="feedback_rating"
                        ref={RefRating}
                        value={rating}
                        onChange={rating => {
                            setRating(rating);
                           // console.log("rating",rating)
                          }}
                        allowHalf={false}
                        className="stars"
                        disabled = {isDisable}
                    />
                    {console.log("editable",isDisable)}
                    <div className="grid-conatianer" style={{display:'flex'}}>
                    <textarea
                        style={{width:'80%',margin:'auto'}}
                        ref={RefComment}
                        rows="2"
                        cols="50"
                        onChange={onChange}        
                        placeholder="Enter Feedback Comment"
                        type="text"
                        name="feedback_comment"
                        required
                        disabled = {isDisable}
                    />
                    <button ref={RefSubmit} className="submitFeedbackButton" type="submit" disabled ={isDisable}>Submit</button>                 
                    </div>   
          </form>
  );
};


