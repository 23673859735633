import { useEffect, useState, useRef } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { Loader } from "../components/loader";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { issueListSelector } from '../slices/IssueList'
import { fetchIssueDetails, StoreApiCallCount, StoreIsRunning } from '../slices/issues'
import SessionPoller from '../components/sessionPoller';
import { protectedResources } from "../authConfig";
import { updateIssuesInRedux } from "../slices/IssueList";
import { useInterval } from "../components/usePolling";

export const IssueContent = () => {

    const { issueList, selectedFilter } = useSelector(issueListSelector)
    //const LocalissueList =  issueList.length<=0 ? issueListJSON.parse(sessionStorage.issueList);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [issueData, setIssueData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedIssueId, setSelectedIssueId] = useState(0);
    const ref = useRef(null);
    let author = "";
    if (instance) {
        let activeAccount = instance.getActiveAccount();
        if (activeAccount.hasOwnProperty('idTokenClaims')) {
            if (activeAccount.idTokenClaims.hasOwnProperty('email')) {
                author = activeAccount.idTokenClaims.email.toLowerCase();
            }
        }
    }

    const [show, setShow] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filter, setFilter] = useState('myopen'); //This is filter value

    const handleChange = (e) => {
        setLoading(true);
        setFilter(e.target.value);
        fetchIssues(1,100,e.target.value);
    };

    const fetchIssues = async (page, pageSize, filter = selectedFilter) => {
        //setLoading(true);
        const headers = new Headers();
        const options = {
          method: "GET",
          headers: headers
        };
    
        let queryParams = ""
        let queryString = "";
        
        switch (filter) {
          case 'myopen':
            queryParams = `&state=new,agent-replied,waiting-for-agent,new-for-agent&author_emails=${author}`;
            break;
          case 'myclosed':
            queryParams = `&state=resolved,rejected&author_emails=${author}`;
            break;
          default:
            queryParams = "";
            break;
        }
    
        queryString = `?page=${page}&page-size=${pageSize}${queryParams}`;
    
        const accessToken = localStorage.getItem("SavedToken");
        headers.append("Authorization", accessToken);
        await fetch(protectedResources.apiGetIssues.endpoint + queryString, options)
          .then(response => response.json())
          .then(response => {
            console.log("fetch user api response ", response);
            dispatch(updateIssuesInRedux(response['issues']));
            //setIssueData(response['issues']);
            //setTotalRows(response['total-hits'])
          })
          .catch(error => {
            console.log(error);
          });
        setLoading(false);
    };

    useInterval(async()=>{
        console.log("selected filter ",filter+" "+selectedFilter);
        fetchIssues(1, 100, filter);
    },15000);
 

    useEffect(()=>{
        console.log("called");
        setIssueData(issueList);
    },[issueList])

    useEffect(()=>{
        setLoading(true);
        fetchIssues(1,1000,filter);
        // eslint-disable-next-line
    },[])
   

    const selectIssue = (issue_id) => {
        console.log("Issue Id from Click", issue_id);
        dispatch(fetchIssueDetails(issue_id, author));
        dispatch(StoreApiCallCount(1));
        dispatch(StoreIsRunning(true));
        setSelectedIssueId(issue_id);
        navigate(`/Getissues?id=${issue_id}`);
    };

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    const element = document.getElementById(id);


    useEffect(() => {
        if (issueList?.length <= 0) {
            selectIssue(parseInt(id));
            setLoading(false);
        } else {
            setSelectedIssueId(parseInt(id));
            setLoading(false);
        }
     
        const timeout = setTimeout(() => {
            setShow(true);
        }, 2000)

        element && element.scrollIntoView({ behavior: 'auto', block: "center", inline: "nearest" })
        return () => clearTimeout(timeout)
    }, [show, account]);   // eslint-disable-line react-hooks/exhaustive-deps


    if (isLoading) {
        return <div className="posts-container">
            <Loader />
        </div>
    }

    return (
        <>
            <SessionPoller/>
            <Table>
                <thead>
                    <tr>
                        <th colSpan="3">
                            <select name="filter" value={filter} onChange={handleChange} style={{ width: '100%', height: 'auto', padding: '.1em' }}>
                                <option value='All'>All Issues</option>
                                <option value='myopen'>My Open Issues</option>
                                <option value='myclosed'>My Resolved / Rejected Issues</option>
                            </select>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan="0.5">Id</th>
                        <th colSpan="1.5">Title</th>
                        <th colSpan="1">Status</th>
                        {/* <th>Agent</th> */}
                    </tr>
                </thead>
                {issueData ?
                    issueData.map(({ issue_id, issue_title, issue_status, assignee_name }) => (
                        <tbody style={{ overflow: 'scroll' }} key={issue_id}>
                            <tr key={issue_id} id={issue_id} onClick={() => selectIssue(issue_id)}
                                style={{
                                    border: selectedIssueId === issue_id ? "0px solid #ccc" : "1px solid #ccc", height: '10vh', verticalAlign: 'middle', overflow: 'hidden', fontSize: 'small',
                                    backgroundColor: selectedIssueId === issue_id ? "rgb(231, 252, 241)" : "#fff"
                                }}
                            >
                                <td colSpan="0.5">{issue_id}</td>
                                <td colSpan="1.5" ref={ref}>{issue_title}</td>
                                <td colSpan="1">{(issue_status) === 'new' || (issue_status) === 'new-for-agent' || (issue_status) === 'waiting-for-agent' ? <div className="issue-status-new">{issue_status === 'new' ? "new" : "Open"}</div> :
                                    (issue_status) === 'agent-replied' ? <div className="issue-status-new">{"Open"}</div> :
                                        <div className="issue-status-rejected">{issue_status}</div>}</td>

                                {/* <td>{!assignee_name 
                            
                            ? 
                            (issue_status === "resolved" || issue_status === "rejected" ? <div className="issue-status-rejected">Unassigned</div> : <div className="issue-status-new">Unassigned</div>)
                            : 
                            
                            // (issue_status === "resolved" || issue_status === "rejected" 
                            //     ?   <div className="issue-status-rejected">{assignee_name}</div>
                            //     :   <div className="issue-status-agent-replied">{assignee_name}</div>)
                            
                            }</td> */}
                            </tr>
                        </tbody>
                    )) : <Loader />}
            </Table>

        </>
    );
};
