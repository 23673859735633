import { combineReducers } from 'redux'

import issuesReducer from './issues'
import IssueListReducer from './IssueList'

const rootReducer = combineReducers({
  issues: issuesReducer,
  issueList: IssueListReducer

})

export default rootReducer