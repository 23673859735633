import { createSlice } from '@reduxjs/toolkit'
import { protectedResources } from '../authConfig'

export const initialState = {
  loading: false,
  hasErrors: false,
  issueList: [],
  filteredIssues: [],
  selectedFilter: "",
  isReplySent: false
}

// A slice for issues with our 3 reducers
const issueListSlice = createSlice({
  name: 'issueList',
  initialState,
  reducers: {
    getLoader: state => {
      state.loading = true

    }, 
    getIssueListSuccess: (state, { payload }) => {
      state.issueList = payload
      state.loading = false
      state.hasErrors = false

    },
    getIssueListFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    getAuthor: (state, {payload}) => {
        state.issueList = payload
    },
    StoreFilteredIssues: (state,{payload}) =>{
      state.filteredIssues = payload

    },StoreSelectedFilter: (state,{payload}) => {
      state.selectedFilter = payload

    },getIssueListByTokenSuccess:(state, {payload}) => {
      state.issueList = payload
      state.loading = false
      state.hasErrors = false
    },
    setHasErrors:state =>{
      state.hasErrors = true;
    }
    
  },
})


// Three actions generated from the slice
export const { setHasErrors, getIssueListSuccess,setIsReplySent,getIssueListFailure,getAuthor,StoreFilteredIssues,StoreSelectedFilter,getIssueListByTokenSuccess } = issueListSlice.actions
// A selector
export const issueListSelector = state => state.issueList;

//export const issueSelector = state => state.messages;
// The reducer
export default issueListSlice.reducer

// Asynchronous thunk action


  export function SaveSelectedFilter(selectedValue) {
    return async dispatch => { 
        dispatch(StoreSelectedFilter(selectedValue));
    }
  }

  export function filterIssues(filteredIssues) {
    return async dispatch => { 
        dispatch(StoreFilteredIssues(filteredIssues));
        //console.log("filtered",filteredIssues);
    }
  }

  export function updateIssuesInRedux(issueList){
    return async dispatch => {
      dispatch(getIssueListSuccess(issueList));
    }
  }

  export function fetchIssueList() {
      return async dispatch => {
        if(sessionStorage.issueList){
          const list = JSON.parse(sessionStorage.issueList);         
          dispatch(getIssueListSuccess(list));
        }
    }
  }

  export function fetchAllIssues(issueList,author){
      return async dispatch => {
        dispatch(getIssueListSuccess(issueList));
        const defaultFiltered = issueList.filter(issues =>{
              return (issues.issue_status !== 'resolved' && issues.issue_status !== 'rejected') && issues.author_email.toUpperCase() === author.toUpperCase();
        })
        dispatch(StoreFilteredIssues(defaultFiltered));
      
      }
  }

  export function fetchAllIssuesFailed(){
    return async dispatch => {
      dispatch(setHasErrors(true));
    }
  }

  export function fetchIssueListUsingToken() {
    
    return async dispatch => {       
    const token = localStorage.getItem("SavedToken"); 
    const headers = new Headers();
    headers.append("Access-Control-Allow-Origin", '*');
    const options = {
          method: "POST",
          headers: headers,
    };
    
    headers.append("Authorization", token);
     
    try{
          const response = await fetch(protectedResources.apiGetIssues.endpoint, options)  
          const data = await response.json()  
          const IssueList = data['issues'];
          dispatch(getIssueListByTokenSuccess(IssueList));
        }catch(error){
          dispatch(getIssueListFailure())
        }
  }
}