import { Button } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import logopath from '../images/logo.svg';
import "../styles/App.css";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Typography } from '@mui/material';

export const ErrorPage = () => {
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [org, setOrg] = useState("");
    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    if (instance) {
        var activeAccount = instance.getActiveAccount();
    }

    useEffect(() => {
        if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
            if (!activeAccount.idTokenClaims.hasOwnProperty('Organization')) {
                console.log("Organization not found");
                window.sessionStorage.clear();
                window.localStorage.clear();
                instance.loginRedirect(request);
            } else {
                setOrg(activeAccount.idTokenClaims.Organization);
            }
        }
        // eslint-disable-next-line
    }, [org, activeAccount, instance])

    const handleLogoutRedirect = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        const cookieName = 'shouldRedirect';
        const cookieValue = "false";
        const expires = new Date();
        expires.setDate(expires.getDate() + 7); // Cookie expiration time (7 days in this example)

        // Set the cookie using document.cookie
        document.cookie = `${cookieName}=${cookieValue};expires=${expires.toUTCString()};path=/`;
        instance.logoutRedirect();
        navigate("/");
    };

    useEffect(() => {
        document.body.style.backgroundColor = '#f2f2f2'; // Set the body background color to light gray
        return () => {
          document.body.style.backgroundColor = ''; // Reset the body background color on component unmount
        };
      }, []);


    return (
        <>
            <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center">
                <div className="container">
                    <a href="/" className="navbar-brand d-flex w-50 me-auto">
                        <img
                            alt=""
                            src={logopath}
                            width="40%"
                            height="40%"
                            className="logo"
                        />
                    </a>

                    <div className="navbar-collapse collapse w-100" id="collapsingNavbar3">
                        <ul className="nav navbar-nav ms-auto w-100 justify-content-end">
                            <Button variant="secondary" onClick={handleLogoutRedirect} style={{ marginRight: '5vw' }}>
                                Sign out
                            </Button>

                        </ul>
                    </div>
                </div>
            </nav>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Alert severity="error" variant="filled">
                    You are not authorised to access the App. <br />
                    Contact your admin: error - <br />
                    <Typography variant="body1" component="strong">
                        Organization not found
                    </Typography>
                </Alert>
            </div>
        </>
    );
};