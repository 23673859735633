
import { MsalProvider } from '@azure/msal-react';
import { Route, Routes} from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import './styles/App.css';
import { DisplayIssueTable } from './pages/landingPage';
import { IssuesDisplay } from "./components/IssuesDisplay";
import { Helpcenter } from "./pages/Helpcenter";
import  {Form}  from "./pages/CreateIssue";
import ProtectedRoute from './components/ProtectedRoute';
import { ErrorPage } from './components/unauthorisedError';

const Pages = () => {

   // const isAuthenticated = useIsAuthenticated();

    //console.log("isAuthenticated",isAuthenticated)

    return ( 
    <Routes>       
        <Route path="/home" element={<ProtectedRoute><DisplayIssueTable/></ProtectedRoute>}/>
        <Route path="/GetIssues" element={<ProtectedRoute><IssuesDisplay /></ProtectedRoute> }/>
        <Route path="/helpcenter" element={<ProtectedRoute><Helpcenter /></ProtectedRoute>}/>
        <Route path="/createissue" element={<ProtectedRoute><Form/></ProtectedRoute>}/>
        <Route path="/" element={<ProtectedRoute><DisplayIssueTable /></ProtectedRoute>}/>
        <Route path="/unauthorisedError" element={<ErrorPage/>}/>
    </Routes>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>           
            <PageLayout>
                <Pages />
            </PageLayout>              
        </MsalProvider>
    );
};

export default App;