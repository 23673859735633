import React from "react";

export const Helpcenter = () => {
    return (
      <div className="helpcenter">
        <iframe style ={{height:'90vh',width:'100vw'}} src="https://quotient.helpshift.com/hc/en/?hpn=1&hbhf=1" name="mainIframe" title="Helpcenter"></iframe>
      </div>
    );
  }
  
  