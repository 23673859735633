import { useEffect, useCallback } from "react";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { protectedResources } from "../authConfig";
import { loginRequest } from "../authConfig";

function SessionPoller() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const req = {
    scopes: protectedResources.apiGetIssues.scopes
  }
  const checkSession = useCallback(() => {
    console.log('Checking session...');
    if (isAuthenticated) {
      let activeAccount = instance.getActiveAccount();
      if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
        instance.acquireTokenSilent(req).then(async (response) => {
          let activeAccount = instance.getActiveAccount();
            if (activeAccount && activeAccount.hasOwnProperty('idTokenClaims')) {
                if (!activeAccount.idTokenClaims.hasOwnProperty('Organization')) {
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    window.location.replace("https://business.quotient.com/signin");
                }
            }else{
              window.sessionStorage.clear();
              window.localStorage.clear();
              window.location.replace("https://business.quotient.com/signin");
            }
        }).catch((error) => {
          console.log(error)
          window.sessionStorage.clear();
          window.localStorage.clear();
          instance.loginRedirect(loginRequest).catch((error) => console.log(error));
        });
      }else{
        //window.location.replace("https://business.pdn.quotient.com/signin");
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, instance]);

  useEffect(() => {
    const interval = setInterval(checkSession, 60000);

    return () => clearInterval(interval);
  }, [checkSession]);
}

export default SessionPoller;
