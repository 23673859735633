import '../styles/loader.css';


export const Loader = () => (
  
<div className="loader">
	<span className="spinner1"></span>
	<span className="spinner2"></span>
	<span className="spinner3"></span>
</div>


  
  );