
import { useEffect, useState } from "react";
import moment from "moment";

export const Conversation = (props) => {
    
   const[message,setMesssage] = useState("")
   const zone = moment.tz.guess();
   const shortcutFormatter = Intl.DateTimeFormat(moment.tz(zone).format("z"), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'shortOffset' 
    });

   useEffect(()=>{
    setMesssage(props.message);
   },[props])

   function ImagePreview({ imageUrl }) {
    const imgStyle = {
        width: '100px',
        height: '100px'
      };
        return (
            <div>
            <img src={imageUrl} alt=" " style={imgStyle} />
            </div>
        );
    }
    return (

            <div className={message.origin === 'helpshift' ? "agent-msg-outer-container" : "user-msg-outer-container"} > 
                <div className={message.origin==='helpshift' ? "agent-msg-inner" : "user-msg-inner"} >                                        
                    <p dangerouslySetInnerHTML={{ __html: message.body }} />
                    {
                    message.attachments && message.attachments.map(({file_name,url},index) => (
                         <div key={index}>< ImagePreview imageUrl={url} />
                        <p key={index} ><a style={{display:"table-cell"}} href ={url} target="_blank" rel="noopener noreferrer" download  >{file_name}</a></p>                                            
                         </div>
                    ))}
                    <div className="grid-container">
                        <div className={message.origin==='helpshift' ? "agent-name" : "user-name"}>
                            {/* {message.hasOwnProperty('author') ? (message.author.hasOwnProperty('name')? message.author.name : ""):"" } */}
                        </div>
                        <div className={message.origin==='helpshift' ? "time-agent" : "time-user"}>
                            {shortcutFormatter.format(message.created_at)}
                        </div>                                    
                    </div>                                    
                </div>                        
            </div>        
    );
}
